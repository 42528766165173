import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxCalculator2023 from "../components/tax-calculator-2023-2024"

const TaxCalculator20232024 = () => (
  <Layout>
    {/* <Seo title="Tax Calculator Pakistan - 2023-2024" /> */}
    <TaxCalculator2023></TaxCalculator2023>
  </Layout>
)
export const Head = () => <Seo title="Income Tax Calculator Pakistan - 2023-2024" />

export default TaxCalculator20232024
